/** @flow */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/** Styled Components */
import ButtonRoot, { IconButtonRoot, FabRoot, ButtonLabelRoot } from './style';
import useHover from '../helpers/useHover';

import Icon from '../icon';

const STYLETRON_PROPS = [
  'variant',
  'color',
  'size',
  'align',
  'fullWidth',
  'rounded',
  'margin',
  'raised',
];

const styletronProps = props =>
  STYLETRON_PROPS.reduce(
    (prev, current) => ({ ...prev, [`$${current}`]: props[current] }),
    {},
  );

const standardProps = props =>
  Object.keys(props).reduce(
    (prev, current) => ({
      ...prev,
      ...(STYLETRON_PROPS.indexOf(current) > -1
        ? {}
        : { [current]: props[current] }),
    }),
    {},
  );

/**
 * Custom Styled Button
 * Base button that all other buttons will use
 *
 * @param {*} { children, as, disabled, ...otherProps }
 * @returns
 */
const CustomButton = ({ children, type, as, ...otherProps }) => {
  const [hoverRef, isHovered] = useHover();

  const StyledButton = as;

  return (
    <StyledButton
      ref={hoverRef}
      type={type}
      {...styletronProps(otherProps)}
      $color={otherProps.disabled ? 'textDisabled' : otherProps.color}
      $isHovered={isHovered}
      {...standardProps(otherProps)}
    >
      {children}
    </StyledButton>
  );
};

/**
 * Standard Button
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
const Button = ({ children, startIcon, endIcon, ...otherProps }) => {
  // const margin =
  //   React.Children.count(children) > 0 ? otherProps.$theme.spacing(1) : 0;

  return (
    <CustomButton
      align={startIcon || endIcon ? 'space-between' : 'center'}
      {...otherProps}
    >
      {startIcon
        ? React.cloneElement(startIcon, {
            style: { marginRight: 2, marginBottom: 2 },
          })
        : null}
      <ButtonLabelRoot>{children}</ButtonLabelRoot>
      {endIcon
        ? React.cloneElement(endIcon, {
            style: { marginLeft: 2, marginBottom: 2 },
          })
        : null}
    </CustomButton>
  );
};

/**
 *  Link Button
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const LinkButton = ({ children, to, ...otherProps }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <ButtonRoot
      {...styletronProps(otherProps)}
      ref={hoverRef}
      type={null}
      to={to}
      {...standardProps(otherProps)}
      $as={Link}
      $isHovered={isHovered}
    >
      {children}
    </ButtonRoot>
  );
};

/**
 * Close Button
 *
 * @param {*} { ...otherProps }
 * @returns
 */
export const CloseButton = ({ ...otherProps }) => (
  <CustomButton as={IconButtonRoot} {...otherProps} variant="text" size="tiny">
    <Icon name="cross" size={18} />
  </CustomButton>
);

/**
 * Icon Button
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const IconButton = ({ children, ...otherProps }) => (
  <CustomButton as={IconButtonRoot} {...otherProps}>
    {children}
  </CustomButton>
);

/**
 * Fab
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const Fab = ({ children, ...otherProps }) => (
  <CustomButton as={FabRoot} {...otherProps}>
    {children}
  </CustomButton>
);

/** Component Property Types */
CustomButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  as: PropTypes.oneOf([ButtonRoot, IconButtonRoot, FabRoot, Link]),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  color: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'flex-end',
  ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  margin: PropTypes.number,
  raised: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomButton.defaultProps = {
  as: ButtonRoot,
  type: 'submit',
  color: 'default',
  variant: 'contained',
  size: 'medium',
  align: 'center',
  fullWidth: false,
  rounded: true,
  raised: false,
  disabled: false,
  margin: 0,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

Button.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
};

LinkButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'flex-end',
  ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  margin: PropTypes.number,
  raised: PropTypes.bool,
  disabled: PropTypes.bool,
};

LinkButton.defaultProps = {
  color: 'default',
  variant: 'contained',
  size: 'medium',
  align: 'center',
  fullWidth: false,
  rounded: true,
  raised: false,
  disabled: false,
  margin: 0,
};

IconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Fab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Button;
