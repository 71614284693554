import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from 'apollo-boost';

// Create Interface Link
const interfaceLink = new HttpLink({
  uri: process.env.REACT_APP_INTERFACE_GRAPHQL_API_URI,
  headers: {
    Consumer: process.env.REACT_APP_INTERFACE_CONSUMER_TOKEN,
  },
});

// Create HolidayGuestWifiVouchers Link
const holidayGuestWifiVoucherLink = new HttpLink({
  uri: process.env.REACT_APP_HOLIDAYGUESTWIFIVOUCHERS_GRAPHQL_API_URI,
  headers: {
    'x-api-key': process.env.REACT_APP_HOLIDAYGUESTWIFIVOUCHERS_API_KEY,
  },
});

export const client = new ApolloClient({
  connectToDevTools: process.browser,
  ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
  link: ApolloLink.split(
    // Function that if returns true, runs interfaceLink otherwise
    // it defaults to holidayGuestWifiVoucherLink
    operation =>
      operation.getContext().clientName !== 'holidayGuestWifiVoucherLink', // Routes the query to the proper client
    interfaceLink,
    holidayGuestWifiVoucherLink,
  ),
  cache: new InMemoryCache(),
  // fetchOptions: {
  //   mode: 'no-cors',
  // },
});
