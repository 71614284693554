import { styled } from '@parkholidays/legacy_components'

export default styled('div', ({ $theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: '1',
  height: '80px',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  backgroundColor: $theme.palette.holidays,
  borderTopLeftRadius: $theme.shape.medium,
  borderTopRightRadius: $theme.shape.medium,
}))
