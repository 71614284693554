import React from 'react';
import PropTypes from 'prop-types';

import CardRoot, {
  CardHeaderRoot,
  CardActionsRoot,
  CardMediaRoot,
  CardContentRoot,
} from './style';

import Theme from '../themes';

const Card = ({
  children,
  color,
  elevation,
  outlined,
  rounded,
  staticContext,
  ...otherProps
}) => {
  /** ADDED FOLLOWING TO RESOLVE STATICCONTEXT BEING PASSED TO DOM ERROR */
  // eslint-disable-next-line no-param-reassign
  if (staticContext) otherProps.staticContext = staticContext;
  /** END */

  return (
    <CardRoot
      $color={color}
      $elevation={elevation}
      $outlined={outlined}
      $rounded={rounded}
      {...otherProps}
    >
      {children}
    </CardRoot>
  );
};

export const CardActions = ({ children, ...otherProps }) => {
  return <CardActionsRoot {...otherProps}>{children}</CardActionsRoot>;
};

export const CardMedia = ({ image, ...otherProps }) => {
  return <CardMediaRoot $image={image} {...otherProps} />;
};

export const CardHeader = ({ children, compact, color, ...otherProps }) => {
  return (
    <CardHeaderRoot $compact={compact} $color={color} {...otherProps}>
      {children}
    </CardHeaderRoot>
  );
};

export const CardContent = ({ children, ...otherProps }) => {
  return <CardContentRoot {...otherProps}>{children}</CardContentRoot>;
};

/** Component Property Types */
// eslint-disable-next-line react/no-typos
Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  elevation: PropTypes.number,
  outlined: PropTypes.bool,
  rounded: PropTypes.bool,
};

Card.defaultProps = {
  color: 'default',
  elevation: 0,
  outlined: false,
  rounded: true,
};

CardHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  compact: PropTypes.bool,
};

CardHeader.defaultProps = {
  color: 'inherit',
  compact: false,
};

CardActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CardContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CardContent.defaultProps = {
  children: undefined,
};

export default Card;
