import React from 'react'
import PropTypes from 'prop-types'
import Container from '@parkholidays/legacy_components/dist/container'
import Typography from '@parkholidays/legacy_components/dist/typography'
import FormField from '@parkholidays/legacy_components/dist/formfield'
import Button from '@parkholidays/legacy_components/dist/button'
import Card, { CardContent } from '@parkholidays/legacy_components/dist/card'
import withCheckoutLayout from '../../layouts/withCheckoutLayout'
import CheckoutHeader from '../../components/CheckoutHeader'

const propTypes = {
  onHandleHolidayBookingValidation: PropTypes.func.isRequired,
}

const Authorisation = ({ onHandleHolidayBookingValidation, errorMessage }) => {
  const [state, setState] = React.useState({
    holidayBookingReference: '',
  })

  const onHandleChange = (e) => {
    const {
      target: { name, value },
    } = e
    setState({
      ...state,
      [name]: value,
    })
  }

  const onHandleSubmit = (e) => {
    e.preventDefault()
    const { holidayBookingReference } = state
    // go to GraphQL, check booking ref, if found, return ref
    // else error and stay on page
    onHandleHolidayBookingValidation(holidayBookingReference.toUpperCase())
  }

  return (
    <>
      <CheckoutHeader>
        <Container>
          <Typography as="h1" noMargins>
            Booking Reference
          </Typography>
          <Typography as="p" noMargins>
            Enter your booking reference number to access Wi-Fi connection in
            your accommodation. This can be found at the top of your
            confirmation or on your welcome pack envelope.
          </Typography>
        </Container>
      </CheckoutHeader>
      <Container>
        <Card color="white" elevation={3} $style={{ marginBottom: '16px' }}>
          <CardContent>
            {errorMessage !== '' ? (
              <Typography as="p" color="red">
                {errorMessage}
              </Typography>
            ) : null}
            <form onSubmit={(e) => onHandleSubmit(e)} autoComplete="off">
              <div style={{ marginBottom: '16px' }}>
                <FormField
                  type="text"
                  id="holidayBookingReference"
                  name="holidayBookingReference"
                  label="Booking reference"
                  placeholder="i.e BKG0000001"
                  value={state.holidayBookingReference}
                  onChange={(e) => onHandleChange(e)}
                  pattern="^([Bb][Kk][Gg])*\d{6,7}$"
                  required
                />
              </div>
              <Button type="submit" color="corporate">
                Validate
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

Authorisation.propTypes = propTypes

export default withCheckoutLayout(Authorisation)
