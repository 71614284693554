/** @flow */
import React from 'react';
import PropTypes from 'prop-types';

import ContainerRoot from './style';

/**
 * Anchor
 *
 * @param {*} props
 * @returns
 */
const Container = ({ children, fluid, marginBottom, ...otherProps }) => {
  return (
    <ContainerRoot {...otherProps} $fluid={fluid} $marginBottom={marginBottom}>
      {children}
    </ContainerRoot>
  );
};

/** Component Property Types */
// eslint-disable-next-line react/no-typos
Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fluid: PropTypes.bool,
  marginBottom: PropTypes.bool,
};

Container.defaultProps = {
  fluid: false,
  marginBottom: false,
};

export default Container;
