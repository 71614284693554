import React from 'react'
import ReactDOM from 'react-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react'
import { DefaultTheme, BaseProvider } from '@parkholidays/legacy_components'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './config/apolloClient'
import {initDatadogConsoleLogger} from "./datadogLogger/logger";
import ErrorBoundary from './ErrorBoundary'

import App from './App'

initDatadogConsoleLogger()

const isProduction = window.location.href.includes('staging') ? false : process.env.NODE_ENV === 'production' 
console.log('isProduction', isProduction);
const debug = isProduction ? undefined : new DebugEngine()
const engine = new Styletron()

ReactDOM.render(
  <ErrorBoundary>
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <BaseProvider theme={DefaultTheme}>
        <ApolloProvider client={client}>
          <App isProduction={isProduction}/>
        </ApolloProvider>
      </BaseProvider>
    </StyletronProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)
