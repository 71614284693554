/** @flow */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

/** Components */
import AppbarRoot, { AppbarToolbar, AppbarBrandRoot } from './style';

export const AppbarBrand = props => {
  const styles = {
    lineHeight: 0,
    ...(props.style || {}),
  };

  return <AppbarBrandRoot {...props} style={styles} $color="inherit" />;
};

/**
 * Application Header Bar
 *
 * @param {*} props
 * @returns
 */
const Appbar = ({ children, color, fluid, ...otherProps }) => {
  return (
    <AppbarRoot role="banner" $color={color} {...otherProps}>
      <AppbarToolbar $fluid={fluid}>{children}</AppbarToolbar>
    </AppbarRoot>
  );
};

/** Component Property Types */
Appbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.oneOf([
    'default',
    'corporate',
    'holidays',
    'touring',
    'ownership',
    'error',
    'black',
    'white',
  ]),
  fluid: PropTypes.bool,
};

Appbar.defaultProps = {
  color: 'corporate',
  fluid: false,
};

export default Appbar;
