import React from 'react'
import {useApolloClient} from '@apollo/react-hooks'

import ProductSelection from './views/ProductSelection'
import Authorisation from './views/Authorisation'
import Payment from './views/Payment'
import Confirmation from './views/Confirmation'
import {datadogLogger} from './datadogLogger/logger'
import { dnaPaymentsPrd, dnaPaymentsTest } from './config/urls'

import {GET_BOOKING, GENERATE_WIFI_VOUCHER} from './queries'
//deploy2
export default function App({isProduction}) {
    const [state, setState] = React.useState({
        // Stage of the checkout flow, as defined by the
        // constant
        stage: 1,
        // The page which the customer originated from, and
        // concludes the URL that we need to redirect the
        // user back to after a successful transaction.
        referrer: '',
        // Holiday booking reference number, used to prepend
        // to the payment receipt reference
        reference: '',
        // Park Code is returned from validateBookingReference
        // and is used for Payment to identify the store
        parkCode: '',
        // Park Id is used to allocate the payment to the
        // correct park within Optomany
        parkId: '',
        // Product that has been selected for purchase
        selectedProduct: undefined,
        // Wifi voucher the customer can use to log into
        // the network after a successful transaction
        voucher: '',
        // If an error is thrown from any step, we'll hoist
        // it to the higher component of App and display an
        // error notification to the customer
        errorMessage: '',
        // Client Mac is used in combination with AP Mac to
        // provide internet access for the duration of checkout
        clientMac: '',
        // Ap Mac is used in combination with Client Mac to
        // provide internet access for the duration of checkout
        apMac: '',
    })
    const client = useApolloClient()

    const handleError = (errorMessage) => {
        setState({
            ...state,
            errorMessage,
        })
    }

    React.useEffect(() => {
        const setupCheckout = async () => {
            // const { referrer } = document
            const queryString = new URLSearchParams(window.location.search)
            const clientMac = queryString.get('client_mac')
            const apMac = queryString.get('ap_mac')
            // const returnUrl = queryString.get('return_url')

            setState((prevState) => ({
                ...prevState,
                // referrer: returnUrl,
                clientMac,
                apMac,
            }))
        }

        // Call the setupCheckout
        setupCheckout()
    }, [])

    const onHandleProductSelection = (product) => {
        setState({
            ...state,
            // If the customer has already authorised (as in, we have a reference)
            // then let them straight through to the payment as they've already
            // confirmed their holiday booking
            stage: !state.reference ? 2 : 3, // @todo - improve on this
            selectedProduct: product,
        })
    }

    const onHandleHolidayBookingValidation = async (reference) => {
        try {
            // Use interface graphql to validate a holiday
            // guests booking, which is used against the
            // payment reference later in the checkout flow
            const {
                data: {validateHolidayBooking},
            } = await client.query({
                query: GET_BOOKING,
                variables: {
                    bookingNo: reference,
                },
            })

            if (validateHolidayBooking) {
                const {
                    valid,
                    park
                } = validateHolidayBooking
                if (valid) {
                    const {
                        code,
                        id
                    } = park
                    // If successful, store the booking reference
                    // and move on to the next stage in the checkout
                    const {
                        clientMac,
                        apMac
                    } = state

                    datadogLogger.info(`about to call guests code-${code} id-${id} clientMac-${clientMac} apMac-${apMac}`)
                if(isProduction){
                    const xhr = new XMLHttpRequest()
                    xhr.open('POST', 'https://api.hotspot.vfastpark.co.uk/guests', true)
                    xhr.setRequestHeader('Content-Type', 'application/json')
                    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
                    xhr.setRequestHeader(
                        'Access-Control-Allow-Methods',
                        'DELETE, POST, OPTIONS',
                    )
                    xhr.setRequestHeader(
                        'Access-Control-Allow-Headers',
                        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                    )
                    xhr.setRequestHeader(
                        'Authorization',
                        'Basic Q0U3QThCQUUtRDk4Qi00RDI4LUJDOUUtMjNGNUY1NDc0OTI4OjJON2FZV1ZmSDZUSw==',
                    )
                    xhr.ontimeout = (e) => {
                        datadogLogger.info(`ontimeout failure json response type vfast-response- responseText-${xhr.responseText} status-${xhr.status}`)
                    };

                    xhr.onload = () => {
                        const textResponses = ["", "text"]
                        if (textResponses.includes(xhr.responseType)) {
                            datadogLogger.info('vfast-response', xhr.responseText)
                        }
                        if (xhr.responseType === "json") {
                            datadogLogger.info('vfast-response', JSON.parse(xhr.responseText))
                        }

                        const {status} = JSON.parse(xhr.responseText)
                        if (status === 'ok') {
                            setState({
                                ...state,
                                stage: 3,
                                reference,
                                parkCode: code,
                                parkId: id,
                            })
                        } else {
                            datadogLogger.info('error occurred in onload')
                            handleError(
                                'Could not establish Internet Access. Please try again later or contact the Park Reception.',
                            )
                        }
                    }
                    xhr.onerror = () => {
                         const textResponses = ["", "text"]
                         if (textResponses.includes(xhr.responseType)) {
                            datadogLogger.info(`onerror failure text response type vfast-response- ${xhr.responseText}`)
                         }
                         if (xhr.responseType === "json") {
                            datadogLogger.info(`onerror failure json response type vfast-response- ${JSON.parse(xhr.responseText)}`)
                         }
                         datadogLogger.info('error hit handler xhr.onerror')
                         datadogLogger.info(`onerror failure unknown response type vfast-response- responseText-${xhr.responseText} status-${xhr.status}`)
                 
                            throw new Error(
                                'Could not establish Internet Access. Please try again later or contact the Park Reception.',
                            )
                    }
                    xhr.send(
                        JSON.stringify({
                            park: code,
                            guest: {
                                client_mac: clientMac,
                                ap_mac: apMac,
                                minutes: 15,
                            },
                        }),
                    )
                } else {
                    setState({
                        ...state,
                        stage: 3,
                        reference,
                        parkCode: code,
                        parkId: id,
                    })
                }
                } else {
                    datadogLogger.info('INVALID')
                    handleError(
                        "Couldn't validate your booking number, please check and try again",
                    )
                }
            }
        } catch (error) {
            throw new Error(error || 'Error on onHandleHolidayBookingValidation.')
        }
    }

    const onHandlePaymentSuccess = async () => {
        const {
            reference,
            selectedProduct: {duration},
            parkCode,
        } = state
        // payment has been successful, now we need to:
        //   1. generate a wifi voucher
        //   2. present wifi voucher on screen
        try {
            // Generate a wifi voucher from our AppSync WifiTokens
            // endpoint, which returns a voucher code the customer
            // can use on the park wifi network
            const {
                data: {createHolidayGuestWifiVoucher},
            } = await client.mutate({
                mutation: GENERATE_WIFI_VOUCHER,
                context: {clientName: 'holidayGuestWifiVoucherLink'},
                variables: {
                    createHolidayGuestWifiVoucherInput: {
                        bookingNo: reference,
                        duration,
                        parkCode,
                    },
                },
            })

            if (
                createHolidayGuestWifiVoucher &&
                Object.prototype.hasOwnProperty.call(
                    createHolidayGuestWifiVoucher,
                    'voucher',
                )
            ) {
                const {voucher} = createHolidayGuestWifiVoucher
            if(isProduction) {
                const {
                    clientMac,
                    parkCode
                } = state
                // If successful, store the voucher code so we can
                // display it to the user in the next stage, and move
                // the checkout onto the confirmation stage.
                const xhr = new XMLHttpRequest()
                xhr.open('DELETE', 'https://api.hotspot.vfastpark.co.uk/guests', true)
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
                xhr.setRequestHeader(
                    'Access-Control-Allow-Methods',
                    'DELETE, POST, OPTIONS',
                )
                xhr.setRequestHeader(
                    'Access-Control-Allow-Headers',
                    'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                )
                xhr.setRequestHeader(
                    'Authorization',
                    'Basic Q0U3QThCQUUtRDk4Qi00RDI4LUJDOUUtMjNGNUY1NDc0OTI4OjJON2FZV1ZmSDZUSw==',
                )
                xhr.onload = () => {
                    const {status} = JSON.parse(xhr.responseText)
                    if (status === 'ok') {
                        setState({
                            ...state,
                            voucher,
                            stage: 4,
                        })
                    }
                }
                xhr.onerror = () => {
                    const textResponses = ["", "text"]

                    if (textResponses.includes(xhr.responseType)) {
                        datadogLogger.info(`onHandlePaymentSuccess onerror failure text response type vfast-response- ${xhr.responseText}`)
                    }
                    if (xhr.responseType === "json") {
                        datadogLogger.info(`onHandlePaymentSuccess onerror failure json response type vfast-response- ${JSON.parse(xhr.responseText)}`)
                    }
                    datadogLogger.info(`onHandlePaymentSuccess onerror failure unknown response type vfast-response- ${xhr.responseText}`)

                    throw new Error(
                        'No "voucher" found in createHolidayGuestWifiVoucher.',
                    )
                }
                xhr.send(
                    JSON.stringify({
                        park: parkCode,
                        guest: {
                            client_mac: clientMac,
                        },
                    }),
                )
            } else {
                setState({
                    ...state,
                    voucher,
                    stage: 4,
                })
            }
            } else {
                handleError("No 'voucher' found in createHolidayGuestWifiVoucher.")
            }
        } catch (error) {
            throw new Error(error || 'Error on onHandlePaymentSuccess.')
        }
    }

    React.useEffect(() => {
      const src = isProduction ? dnaPaymentsPrd : dnaPaymentsTest;
      const script = document.createElement('script');
      script.src = src
      document.body.appendChild(script);
    }, [isProduction])

    // Set up all the stages and their respective
    // component (view), whilst passing through the
    // relevant props from the App state.
    const stages = {
        1: <ProductSelection onHandleProductSelection={onHandleProductSelection}/>,
        2: (
            <Authorisation
                onHandleHolidayBookingValidation={onHandleHolidayBookingValidation}
                errorMessage={state.errorMessage}
            />
        ),
        3: (
            <Payment
                selectedProduct={state.selectedProduct}
                reference={state.reference}
                parkId={state.parkId}
                onHandlePaymentSuccess={onHandlePaymentSuccess}
                isProduction={isProduction}
                parkCode={state.parkCode}
            />
        ),
        4: (
            <Confirmation
                selectedProduct={state.selectedProduct}
                voucher={state.voucher}
                parkCode={state.parkCode}
                // referrer={state.referrer}
            />
        ),
    }

    // Dynamically assign the component based on the stage
    const StageComponent = stages[state.stage]

    return React.cloneElement(StageComponent)
}