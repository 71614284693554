/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import BaseLabel from './style';

import { DefaultTheme } from '../index';
const $theme = { ...DefaultTheme }; // hack to make existing code work...

/**
 * Base Component
 *
 * @param {*} {
 *   children,
 *   labelPosition,
 *   control,
 *   align,
 *   fullWidth,
 *   ...otherProps
 * }
 * @returns
 */
const BaseComponent = ({
  children,
  labelPosition,
  color,
  control,
  align,
  fullWidth,
  srOnly,
  ...otherProps
}) => {
  const textColor =
    color === 'inherit'
      ? color
      : $theme.palette[color] ||
        $theme.palette.common[color] ||
        $theme.palette.text;

  return (
    <BaseLabel
      {...otherProps}
      $labelPosition={labelPosition}
      $fullWidth={fullWidth}
      $srOnly={srOnly}
    >
      {control ? (
        <span style={{ paddingRight: $theme.spacing(1) }}>{control}</span>
      ) : null}
      <span style={{ flex: 1, textAlign: align, color: textColor }}>
        {children}
      </span>
    </BaseLabel>
  );
};

/**
 *  Form Label
 *
 * @param {*} { children, control, ...otherProps }
 * @returns
 */
const FormLabel = ({ children, control, ...otherProps }) => {
  const controlId = otherProps.id || `formControl_${new Date().valueOf()}`;
  return (
    <BaseComponent
      id={`${controlId}_label`}
      htmlFor={controlId}
      control={control ? React.cloneElement(control, { id: controlId }) : null}
      {...otherProps}
    >
      {children}
    </BaseComponent>
  );
};

/** Component Property Types */
// eslint-disable-next-line react/no-typos
BaseComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  control: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  labelPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  align: PropTypes.oneOf(['initial', 'left', 'right', 'center', 'justify']),
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  srOnly: PropTypes.bool,
};

BaseComponent.defaultProps = {
  control: undefined,
  labelPosition: 'right',
  align: 'initial',
  fullWidth: false,
  color: 'default',
  srOnly: false,
};

FormLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  control: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  labelPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  align: PropTypes.oneOf(['initial', 'left', 'right', 'center', 'justify']),
  fullWidth: PropTypes.bool,
};

FormLabel.defaultProps = {
  labelPosition: 'right',
  control: undefined,
  align: 'initial',
  fullWidth: false,
};

export default FormLabel;
