import { gql } from 'apollo-boost';

export const GET_BOOKING = gql`
  query($bookingNo: BookingNo!) {
    validateHolidayBooking(bookingNo: $bookingNo) {
      valid
      park {
        id
        name
        code
      }
    }
  }
`;

export const GENERATE_WIFI_VOUCHER = gql`
  mutation createHolidayGuestWifiVoucher(
    $createHolidayGuestWifiVoucherInput: CreateHolidayGuestWifiVoucherInput!
  ) {
    createHolidayGuestWifiVoucher(input: $createHolidayGuestWifiVoucherInput) {
      id
      duration
      voucher
      validFrom
      validTo
    }
  }
`;
