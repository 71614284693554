import { styled } from '@parkholidays/legacy_components'

export default styled('div', ({ $theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '16px',
  justifyContent: 'center',
  '@media screen and (min-width: 414px)': {
    justifyContent: 'space-between',
  },
}))
