import { withStyle } from '../styles/index.js';

import { CardContentRoot } from '../card/style';

const ContentRoot = withStyle(CardContentRoot, ({ $theme }) => ({
  position: 'relative',
  backgroundColor: $theme.palette.common.white,
  paddingLeft: `${$theme.spacing(2)}px !important`,
  paddingRight: `${$theme.spacing(2)}px !important`,
  paddingTop: `${$theme.spacing(3)}px`,
  paddingBottom: `${$theme.spacing(2)}px`,
  // ':after': {
  //   content: '""',
  //   position: 'absolute',
  //   left: '50%',
  //   bottom: '-20px',
  //   marginLeft: '-20px',
  //   width: '0px',
  //   height: '0px',
  //   borderLeft: '20px solid transparent',
  //   borderRight: '20px solid transparent',
  //   borderTop: `20px solid ${$theme.palette.common.white}`,
  // },
}));

export default ContentRoot;
