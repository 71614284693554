import React from 'react';
import PropTypes from 'prop-types';

import GridContainer, { GridItem } from './style';

import { DefaultTheme } from '../index';
const defaultTheme = { ...DefaultTheme }; // hack to make existing code work...

/** GRID SIZES */
// const GRID_SIZES = [...new Array(12)].map((v, i) => i + 1);

/**
 * Grid Component
 *
 * @param {*} {
 *   children,
 *   item,
 *   justify,
 *   alignItems,
 *   noGutters,
 *   ...otherProps
 * }
 * @returns
 */
const Grid = ({
  children,
  item,
  justify,
  alignItems,
  noGutters,
  ...otherProps
}) => {
  const Component = item ? GridItem : GridContainer;
  const conditionalProps = item
    ? defaultTheme.breakpoints.keys.reduce(
        (prev, current) => ({
          ...prev,
          ...(otherProps[current]
            ? { [`$${current}`]: otherProps[current] }
            : {}),
          ...(otherProps[`${current}Offset`]
            ? { [`$${current}Offset`]: otherProps[`${current}Offset`] }
            : {}),
        }),
        {},
      )
    : {};

  const newProps = Object.keys(otherProps)
    .filter(key => !conditionalProps[`$${key}`])
    .reduce(
      (prev, current) => ({ ...prev, [current]: otherProps[current] }),
      {},
    );

  return (
    <Component
      {...conditionalProps}
      {...newProps}
      $justify={justify}
      $alignItems={alignItems}
      $noGutters={noGutters}
    >
      {React.Children.map(children, child =>
        !item && child ? React.cloneElement(child, { noGutters }) : child,
      )}
    </Component>
  );
};

/** Component Property Types */
Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  item: PropTypes.bool,
  noGutters: PropTypes.bool,
  // Alignment
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  alignItems: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline',
  ]),
};

Grid.defaultProps = {
  item: false,
  noGutters: false,
  justify: undefined,
  alignItems: undefined,
};

export default Grid;
