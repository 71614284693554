import React from 'react'

import Appbar, { AppbarBrand } from '@parkholidays/legacy_components/dist/appbar'
import Image from '@parkholidays/legacy_components/dist/image'
import {privacyPolicy} from '../config/urls'

function withCheckoutLayout(Component) {
  // build
  function CheckoutLayout(props) {
    return (
      <>
        <Appbar
          color="corporate"
          $style={{
            paddingTop: '8px',
            borderBottomWidth: '4px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#2e9cc0',
            paddingBottom: '8px',
          }}
        >
          <AppbarBrand
            to="/"
            $style={{
              display: 'flex',
              gap: '1rem',
              '@media (max-width: 767px)': {
                gap: '2rem',
              },
            }}
          >
            <Image
              src="https://assets.parkholidays.com/static_assets/svg_logo/white_redflag.svg"
              alt="Park Holidays UK Logo"
              width="150"
            />
            <Image
              src="https://assets.parkholidays.com/assets/5077532769d88aaf403005dd862ef678.png"
              alt="Park Leisure Logo"
              width="150"
            />
          </AppbarBrand>
          <a
            href={privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', fontSize: '1.25rem', fontWeight: '700' }}
          >
            Privacy Policy
          </a>
        </Appbar>
        <main
        >
          <Component {...props} />
        </main>
      </>
    )
  }

  return CheckoutLayout
}

export default withCheckoutLayout
