/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../typography';
import FormLabel from '../formlabel';
import FormFieldRoot from './style';

/**
 *  Form Field
 *
 * @param {*} {
 *   children,
 *   value,
 *   multiline,
 *   select,
 *   label,
 *   helperText,
 *   transparent,
 *   fullWidth,
 *   rounded,
 *   onChange
 * }
 * @returns
 */
const FormField = ({
  children,
  value,
  multiline,
  label,
  helperText,
  transparent,
  fullWidth,
  rounded,
  labelProps,
  beforeEnhancer,
  afterEnhancer,
  onChange,
  ...otherProps
}) => {
  const { id, type, style } = otherProps;
  const fieldId = id || `textField_${new Date().valueOf()}`;

  const styles = {
    ...(style || {}),
    ...(beforeEnhancer
      ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
      : {}),
    ...(afterEnhancer
      ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
      : {}),
  };

  // set element to render as
  let as = 'input';
  if (multiline) as = 'textarea';

  // Methods
  const handleChange = event => {
    if (onChange) onChange(event);
  };

  const Control = (
    <FormFieldRoot
      $as={as}
      {...otherProps}
      id={fieldId}
      type={multiline ? null : type}
      value={value}
      $fullWidth={fullWidth}
      $rounded={rounded}
      $transparent={transparent}
      aria-labelledby={`${fieldId}_label`}
      aria-describedby={helperText ? `${fieldId}_helperText` : null}
      style={styles}
      onChange={handleChange}
    >
      {multiline ? children : null}
    </FormFieldRoot>
  );

  return (
    <>
      {label ? (
        <FormLabel
          id={`${fieldId}_label`}
          htmlFor={fieldId}
          fullWidth
          {...(labelProps || {})}
          style={{
            marginBottom: 8,
            ...((labelProps && labelProps.style) || {}),
          }}
        >
          {label}
        </FormLabel>
      ) : null}

      {beforeEnhancer || afterEnhancer ? (
        <div style={{ display: 'flex', width: fullWidth ? '100%' : 'auto' }}>
          {beforeEnhancer
            ? React.cloneElement(beforeEnhancer, {
                style: {
                  ...(beforeEnhancer.props.style || {}),
                  zIndex: 1,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  marginRight: -1,
                },
              })
            : null}
          {Control}
          {afterEnhancer
            ? React.cloneElement(afterEnhancer, {
                style: {
                  ...(afterEnhancer.props.style || {}),
                  zIndex: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  marginLeft: -1,
                },
              })
            : null}
        </div>
      ) : (
        Control
      )}

      {helperText ? (
        <Typography as="small" color="textHint" id={`${fieldId}_helperText`}>
          {helperText}
        </Typography>
      ) : null}
    </>
  );
};

/** Component Property Types */
// eslint-disable-next-line react/no-typos
FormField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  transparent: PropTypes.bool,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  labelProps: PropTypes.object,
  beforeEnhancer: PropTypes.node,
  afterEnhancer: PropTypes.node,
  onChange: PropTypes.func,
};

FormField.defaultProps = {
  children: undefined,
  value: '',
  multiline: false,
  label: undefined,
  helperText: undefined,
  transparent: false,
  fullWidth: false,
  rounded: true,
  labelProps: {},
  beforeEnhancer: undefined,
  afterEnhancer: undefined,
  onChange: () => {},
};

export default FormField;
