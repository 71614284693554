const privacyPolicy = 'https://www.parkholidays.com/privacy-policy';
const paymentSuccessPrd =  'https://pay.dnapayments.com/checkout/success.html';
const paymentSuccessTest =  'https://test-pay.dnapayments.com/checkout/success.html';

const paymentFailurePrd =  'https://pay.dnapayments.com/checkout/failure.html';
const paymentFailureTest = 'https://test-pay.dnapayments.com/checkout/failure.html';

const paymentClientPrd =  'https://6wg5c22rfe.execute-api.eu-west-2.amazonaws.com/default/niobe__production';
const paymentClientStaging =  'https://1bzg25lste.execute-api.eu-west-2.amazonaws.com/default/niobe__staging';

const dnaPaymentsPrd = 'https://pay.dnapayments.com/checkout/payment-api.js';
const dnaPaymentsTest = 'https://test-pay.dnapayments.com/checkout/payment-api.js';

export { dnaPaymentsPrd, dnaPaymentsTest, privacyPolicy, paymentSuccessPrd, paymentSuccessTest, paymentFailurePrd, paymentFailureTest, paymentClientPrd, paymentClientStaging }
