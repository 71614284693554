/** @flow */
import React from 'react';
import PropTypes from 'prop-types';

import Theme from '../themes';
import { BurgerMenuLine } from './style';

/**
 * Burger Menu Icon
 *
 * @param {*} { color, active, ...otherProps }
 * @returns
 */
const BurgerMenuIcon = ({ color, active, ...otherProps }) => {
  const { theme } = React.useContext(Theme);

  const styles = {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    ...(otherProps.style || {}),
  };

  return (
    <div {...otherProps} style={styles}>
      {[...new Array(3)]
        .map((v, idx) => idx + 1)
        .map(v => (
          <BurgerMenuLine
            key={v}
            $bar={v}
            $color={color}
            $active={active}
            $theme={theme}
          />
        ))}
    </div>
  );
};

/** Component Property Types */
BurgerMenuIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
};

BurgerMenuIcon.defaultProps = {
  active: false,
  color: 'inherit',
};

export default BurgerMenuIcon;
