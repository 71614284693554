/** @flow */
import React from 'react';
import PropTypes from 'prop-types';

import Icons from '../icons';
import Theme from '../themes';
// import './icons.css';
import IconSvgRoot from './style';
import BurgerMenuIcon from './burger-menu';

export { BurgerMenuIcon };

// baseSize is the icons.preferences.fontPref.metrics.emSize from selection.json
const baseSize = 1024;

const Icon = ({ name, size, color, ...otherProps }) => {
  const { theme } = React.useContext(Theme);
  const iconPath = Icons.path[name] || [];
  const iconViewBox = Icons.viewBox[name] || `0 0 ${baseSize} ${baseSize}`;

  const attributes = {
    ...otherProps,
    viewBox: iconViewBox,
    // css: className,
  };

  return (
    <IconSvgRoot {...attributes} $size={size} $color={color} $theme={theme}>
      {iconPath.map((path, index) => (
        <path key={btoa(index)} d={path} />
      ))}
    </IconSvgRoot>
  );
};

Icon.defaultProps = {
  size: 16,
  color: 'currentColor',
};

export default Icon;

/** Component Property Types */
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

Icon.defaultProps = {
  variant: undefined,
  color: 'currentColor',
  size: 16,
};
