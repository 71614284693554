import React from 'react';
import PropTypes from 'prop-types';

import Accordian, { AccordianItem } from '../accordian';
import Card from '../card';
import Image from '../image';

import ContentRoot from './style';

import { DefaultTheme } from '../index';
const theme = { ...DefaultTheme }; // hack to make existing code work...

/**
 * Checkout Product Card Content
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const CheckoutProductCardContent = ({ children, ...otherProps }) => {
  return <ContentRoot {...otherProps}>{children}</ContentRoot>;
};

/**
 * Checkout Product Card Hidden Content
 *
 * @param {*} { children, ...otherProps }
 * @returns
 */
export const CheckoutProductCardHidden = ({ children, ...otherProps }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Accordian {...otherProps} onItemToggle={open => setIsOpen(open)}>
      <AccordianItem
        title={!isOpen ? 'more' : 'less'}
        contentProps={{ style: { order: -1, paddingTop: theme.spacing(3) } }}
        style={{
          borderBottomLeftRadius: theme.shape.medium,
          borderBottomRightRadius: theme.shape.medium,
        }}
      >
        {/* <div style={{ padding: 16 }}> */}
        {children}
        {/* </div> */}
      </AccordianItem>
    </Accordian>
  );
};

/**
 * Checkout Product Card Image
 *
 * @param {*} {  }
 * @returns
 */
export const CheckoutProductCardImage = ({ image }) => {
  const imageSrc = `https://park-holidays.s3.eu-west-2.amazonaws.com/assets/checkout/illustrations-${image}.png`; // images[image] || '';

  const styles = {
    wrapper: {
      zIndex: 1,
      height: 114,
      backgroundColor: theme.palette.holidays,
      borderTopLeftRadius: theme.shape.medium,
      borderTopRightRadius: theme.shape.medium,
    },
    img: { display: 'block', margin: '0 auto', width: 128, height: 128 },
  };

  return (
    <div style={styles.wrapper}>
      <Image src={imageSrc} alt={image} style={styles.img} />
    </div>
  );
};

/**
 *  Checkout Product Card
 *
 * @param {*} { children }
 * @returns
 */
const CheckoutProductCard = ({ children }) => (
  <Card elevation={3} style={{ overflow: 'visible' }}>
    {children}
  </Card>
);

export default CheckoutProductCard;

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CheckoutProductCard.propTypes = { children: propTypes.children.isRequired };
CheckoutProductCardContent.propTypes = {
  children: propTypes.children.isRequired,
};
CheckoutProductCardHidden.propTypes = {
  children: propTypes.children.isRequired,
};
