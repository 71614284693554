import React from 'react'
import PropTypes from 'prop-types'
import Container from '@parkholidays/legacy_components/dist/container'
import Grid from '@parkholidays/legacy_components/dist/grid'
import Typography from '@parkholidays/legacy_components/dist/typography'
import Button from '@parkholidays/legacy_components/dist/button'
import CheckoutProductCard, {
  CheckoutProductCardContent,
  CheckoutProductCardImage,
} from '@parkholidays/legacy_components/dist/checkout-product-card'
import products from '../../data/products'
import withCheckoutLayout from '../../layouts/withCheckoutLayout'
import CheckoutHeader from '../../components/CheckoutHeader'

const propTypes = {
  onHandleProductSelection: PropTypes.func.isRequired,
}

const ProductSelection = ({ onHandleProductSelection }) => {
  return (
    <>
      <CheckoutHeader>
        <Container>
          <Typography as="h1" noMargins>
            Select your package
          </Typography>
          <Typography as="p" noMargins>
            Choose your preferred duration below, this will start from the time
            of purchase.
          </Typography>
        </Container>
      </CheckoutHeader>
      <Container>
        <Grid>
          {products.map((product) => {
            const { duration, description, price } = product
            return (
              <Grid item xs={12} md={6} lg={4} key={duration}>
                <CheckoutProductCard
                  key={duration}
                  product={product}
                  onHandleProductSelection={onHandleProductSelection}
                >
                  <CheckoutProductCardImage image={`${duration}day`} />
                  <CheckoutProductCardContent>
                    <Typography as="h3" color="corporate" weight="bold">
                      {`${duration} Day Wi-Fi Voucher`}
                    </Typography>
                    <Typography as="p" noMargins>
                      {description}
                    </Typography>

                    <Typography as="h1" color="rose" weight="bold">
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                      }).format(price)}
                    </Typography>
                    <Button
                      color="corporate"
                      onClick={() => onHandleProductSelection(product)}
                    >
                      {`Buy ${duration} Day Voucher`}
                    </Button>
                  </CheckoutProductCardContent>
                </CheckoutProductCard>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

ProductSelection.propTypes = propTypes

export default withCheckoutLayout(ProductSelection)
