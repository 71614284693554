import React from 'react'
import { datadogLogger } from './datadogLogger/logger'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, eventId: null }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    datadogLogger.info(`Error caught in componentDidCatch ${JSON.stringify(error)}, ${JSON.stringify(errorInfo)}`)
      this.setState({ errorInfo })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>
            Please try again, alternatively, if the issue persists please
            contact us on <a href="tel:03431787070">0343 178 7070</a> where we
            can assist you further.
          </p>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary
