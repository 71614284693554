import { styled, withStyle } from '../styles/index.js';

import ContainerRoot from '../container/style';
import AnchorRoot from '../anchor/style';

// Theme Styles
const themeStyles = ({ $color, swap, $theme }) => {
  const color = $color === 'default' ? 'gray100' : $color;
  const styles = $theme.styler({ color, swap, outlined: false });
  return styles;
};

/** Appbar Root Element Component */
const AppbarRoot = styled('header', ({ $color, $theme }) => ({
  zIndex: $theme.zIndex.appbar,
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  ...themeStyles({ $color, $theme }),
}));

/** Appbar Toolbar Element Component */
export const AppbarToolbar = withStyle(ContainerRoot, ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...$theme.mixins.toolbar,
  [$theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [$theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: `${$theme.spacing(2)}px`,
    paddingTop: `${$theme.spacing(1)}px`,
  },
}));

export const AppbarBrandRoot = withStyle(AnchorRoot, {});

export default AppbarRoot;
