import { styled, withStyle } from '../styles/index.js';
import deepMerge from '../helpers/deepMerge';

const sizing = ({ $theme, ...otherProps }) => {
  const gridSize = 12;
  const styles = Object.keys(otherProps)
    .filter(key =>
      $theme.breakpoints.keys.includes(
        key.replace('$', '').replace('Offset', ''),
      ),
    )
    .reduce(
      (prev, current) => {
        const key = current.replace('$', '');
        const value =
          otherProps[current] !== 'auto'
            ? (100 / gridSize) * otherProps[current]
            : otherProps[current];
        const offset = otherProps[`${current}Offset`] || null;

        return {
          ...prev,
          ...(offset || otherProps[current]
            ? {
                [$theme.breakpoints.up(key)]: {
                  ...(otherProps[current]
                    ? {
                        // flexGrow: value === 'auto' ? 1 : 0,
                        maxWidth: `${value}${value !== 'auto' ? '%' : ''}`,
                        flex: `${value !== 'auto' ? `${value}%` : 1}`,
                      }
                    : {}),
                  ...(offset
                    ? { marginLeft: `${(100 / gridSize) * offset}%` }
                    : {}),
                },
              }
            : {}),
        };
      },
      {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
      },
    );

  return styles;
};

const GridRoot = styled('div', ({ $justify, $alignItems }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  ...($justify ? { justifyContent: $justify } : {}),
  ...($alignItems ? { alignItems: $alignItems } : {}),
}));

const GridContainer = withStyle(GridRoot, ({ $theme, $noGutters }) => ({
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  ...($noGutters ? {} : $theme.mixins.grid),
}));

export const GridItem = withStyle(
  GridRoot,
  ({ $theme, $noGutters, ...otherProps }) =>
    deepMerge(
      {
        flexDirection: 'column',
        // margin: 0,
        width: '100%',
        marginBottom: `${$theme.spacing(2)}px`,
      },
      $noGutters ? {} : $theme.mixins.gutters(),
      sizing({ $theme, ...otherProps }),
    ),
);

export default GridContainer;
