import { styled } from '@parkholidays/legacy_components'

export default styled('img', () => ({
  display: 'block',
  marginTop: '0',
  marginRight: '0',
  marginBottom: '0',
  marginLeft: 'auto',
  width: '64px',
  height: '64px',
}))
