import { styled } from '@parkholidays/legacy_components'

export default styled('input', ({ $theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  marginTop: '8px',
  marginRight: '0',
  marginBottom: '16px',
  marginLeft: '0',
  borderColor: $theme.palette.common.gray50,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderTopLeftRadius: `${$theme.shape.medium}px`,
  borderTopRightRadius: `${$theme.shape.medium}px`,
  borderBottomLeftRadius: `${$theme.shape.medium}px`,
  borderBottomRightRadius: `${$theme.shape.medium}px`,
  textAlign: 'center',
  width: '100%',
  ...$theme.typography.h1,
}))
