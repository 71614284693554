import { styled } from '../styles/index.js';
import deepMerge from '../helpers/deepMerge';

const breakpointStyles = ({ $theme }) => {
  const { keys, values } = $theme.breakpoints;
  const styles = keys
    .filter((key, idx) => values[idx] > 0)
    .reduce((prev, current) => {
      const index = keys.indexOf(current);
      const value = values[index];

      let maxWidth = Math.round((value / 100) * 90);
      if (maxWidth > 1440) maxWidth = 1440;

      return {
        ...prev,
        [`${$theme.breakpoints.up(current)}`]: { maxWidth: `${maxWidth}px` },
      };
    }, {});

  return styles;
};

/** Base Container Component */
const ContainerRoot = styled('div', ({ $fluid, $marginBottom, $theme }) =>
  deepMerge(
    {
      boxSizing: 'border-box',
      marginTop: '0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: `${$marginBottom ? $theme.spacing(2) : 0}px`,
      width: '100%',
      ...($fluid ? {} : breakpointStyles({ $theme })),
    },
    $theme.mixins.gutters(),
  ),
);

export default ContainerRoot;
