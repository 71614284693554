import { styled } from '../styles/index.js';

const borderStyles = ({ $rounded, $error, $theme }) => {
  const color = $error ? $theme.palette.error : '#d9d9d9';

  return {
    borderTop: `1px solid ${color}`,
    borderBottom: `1px solid ${color}`,
    borderRight: `1px solid ${color}`,
    borderLeft: `1px solid ${color}`,
    borderRadius: $rounded ? `${$theme.shape.small}px` : 0,
  };
};

const FormFieldRoot = styled(
  'input',
  ({ $fullWidth, $rounded, $transparent, $theme, $error }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    ...$theme.typography.base,
    ...($fullWidth ? { width: '100%' } : {}),
    minHeight: '44px', // 44
    // Gutters / Padding
    paddingTop: `${$theme.spacing(1)}px`,
    paddingBottom: `${$theme.spacing(1)}px`,
    ...$theme.mixins.gutters(),
    // Borders
    ...borderStyles({ $rounded, $error, $theme }),
    // Transparency
    ...($transparent
      ? {
          border: 0,
          backgroundColor: 'transparent',
          color: 'inherit',
        }
      : {}),
  }),
);

export default FormFieldRoot;
