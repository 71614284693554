/** @flow */
import React from 'react';
import PropTypes from 'prop-types';

// import Theme from '../themes';
import Icon from '../icon';
import AccordianRoot, {
  AccordianItemRoot,
  AccordianItemToggle,
  AccordianItemContent,
} from './style';

import Theme from '../themes';

/**
 * Accordian
 *
 * @param {*} props
 * @returns
 */
const Accordian = ({ children, onItemToggle, ...otherProps }) => {
  const [openIndex, setOpenIndex] = React.useState(null);
  const toggleItems = index => setOpenIndex(openIndex === index ? null : index);

  React.useEffect(() => {
    if (typeof onItemToggle === 'function') onItemToggle(openIndex !== null);
  }, [onItemToggle, openIndex]);

  return (
    <AccordianRoot {...otherProps}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          open: openIndex === index,
          onToggle: () => toggleItems(index),
        }),
      )}
    </AccordianRoot>
  );
};

/**
 * Accordian Item
 *
 * @param {*} props
 * @returns
 */
export const AccordianItem = ({
  children,
  title,
  open,
  color,
  onToggle,
  contentProps,
  togglerProps,
  ...otherProps
}) => {
  const { theme } = React.useContext(Theme);
  return (
    <AccordianItemRoot {...otherProps} $color={color} $theme={theme}>
      <AccordianItemToggle {...togglerProps} onClick={onToggle} $theme={theme}>
        <span style={{ flex: 1 }}>{title}</span>
        <Icon name={open ? 'chevron-up' : 'chevron-down'} />
      </AccordianItemToggle>
      <AccordianItemContent {...contentProps} $open={open} $theme={theme}>
        {children}
      </AccordianItemContent>
    </AccordianItemRoot>
  );
};

export default Accordian;

Accordian.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onItemToggle: PropTypes.func,
};

Accordian.defaultProps = {
  onItemToggle: undefined,
};

AccordianItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  color: PropTypes.string,
  onToggle: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  contentProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  togglerProps: PropTypes.object,
};

AccordianItem.defaultProps = {
  open: false,
  color: 'default',
  onToggle: undefined,
  contentProps: {},
  togglerProps: {},
};
