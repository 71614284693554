export default [
  {
    duration: 1,
    description: 'Connect up to 6 devices for 24 hours.',
    price: 7.99,
  },
  {
    duration: 4,
    description: 'Connect up to 6 devices for up to 4 days.',
    price: 14.99,
  },
  {
    duration: 7,
    description: 'Connect up to 6 devices for up to 7 days.',
    price: 17.99,
  },
]
