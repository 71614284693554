import { styled } from '@parkholidays/legacy_components'

export default styled('div', {
  boxSizing: 'border-box',
  paddingTop: '16px',
  paddingRight: '16px',
  paddingBottom: '16px',
  paddingLeft: '16px',
  backgroundColor: '#fff',
  color: '#666',
  marginBottom: '16px',
})
