/** @flow */
import React from 'react';
import PropTypes from 'prop-types';

/** Styled Components */
import BaseTypography from './style';

import { DefaultTheme } from '../index';
const defaultTheme = { ...DefaultTheme }; // hack to make existing code work...

/**
 * Typography
 *
 * @param {*} props
 * @returns
 */
const Typography = ({
  children,
  variant,
  as,
  color,
  align,
  weight,
  transform,
  noMargins,
  beforeEnhancer,
  afterEnhancer,
  ...otherProps
}) => {
  return (
    <BaseTypography
      {...otherProps}
      $variant={variant}
      $as={as}
      $color={color}
      $align={align}
      $weight={weight}
      $transform={transform}
      $noMargins={noMargins}
      $hasIcon={beforeEnhancer || afterEnhancer}
    >
      {beforeEnhancer}
      {beforeEnhancer || afterEnhancer ? (
        <span
          style={{
            flex: 1,
            paddingLeft: beforeEnhancer ? defaultTheme.spacing(1) : 0,
            paddingRight: afterEnhancer ? defaultTheme.spacing(1) : 0,
          }}
        >
          {children}
        </span>
      ) : (
        children
      )}
      {afterEnhancer}
    </BaseTypography>
  );
};

/** Component Property Types */
// eslint-disable-next-line react/no-typos
Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf([
    'default',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'srOnly',
  ]),
  as: PropTypes.oneOf([
    'small',
    'span',
    'strong',
    'em',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ]),
  color: PropTypes.string,
  align: PropTypes.oneOf(['inherit', 'left', 'right', 'center', 'justify']),
  weight: PropTypes.oneOf([
    'light',
    'regular',
    'medium',
    'semi-bold',
    'bold',
    'bolder',
    'extra-bold',
  ]),
  transform: PropTypes.oneOf(['capitalize', 'uppercase', 'lowercase', 'none']),
  noMargins: PropTypes.bool,
  beforeEnhancer: PropTypes.node,
  afterEnhancer: PropTypes.node,
};

Typography.defaultProps = {
  variant: undefined,
  as: 'span',
  color: 'inherit',
  align: 'inherit',
  weight: 'regular',
  transform: 'none',
  noMargins: false,
  beforeEnhancer: undefined,
  afterEnhancer: undefined,
};

export default Typography;
